// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = (props: PageProps) => (
  <Layout>
    <SEO title="About" />
    <p>Hi, I am Vasusen and I love doing things that have a positive impact on our world.</p>
    <p>I also enjoy naps, coffee, and cooking.</p>
  </Layout>
)

export default About
